// **  Initial State
const initialState = {
  lang: {
  }
}

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'changeLanguage':
      return { ...state, lang: action.payload.lang }
    default:
      return state
  }
}

export default langReducer

// **  Initial State
const initialState = {
  code: ''
}

const tempCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SetTempCode':
      return { ...state, code: action.payload.code }
    default:
      return state
  }
}

export default tempCodeReducer

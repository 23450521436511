import Constants from '@store/Constants.json'
// ** Initial State
const initialState = {
  data: [],
  un_read: 0
}

const notificationReducer = (state = initialState, action) => {
  // console.log("hacker_", action.type === Constants.Notification.read)
  switch (action.type) {
    case Constants.Notification.read:
      return { ...state, un_read: action.un_read, data: action.data }
    default:
      return state
  }
}

export default notificationReducer

import Constants from '@store/Constants.json'
// ** Initial State
const initialState = {
  counter: {
    new: 0,
    start: 0,
    end: 0
  }
}

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.Counter.read:
      return { ...state, counter: { ...action.counter } }
    default:
      return state
  }
}

export default dataReducer
